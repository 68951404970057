<template>
    <van-swipe lazy-render class="my-swipe" :class="load ? null : 'img-loading'">
        <van-swipe-item @click="handleBannerClick(banner)" v-for="banner in dataset" :key="banner.id">
            <my-image :src="banner.thumb"></my-image>
        </van-swipe-item>
    </van-swipe>
</template>

<script>
import MyImage from '@/components/MyImage'
export default {
    name: "Banner",
    props: {
        dataset: Array
    },
    components: { MyImage },
    methods: {
        handleImgLoad () {
            this.load = true
        },
        handleBannerClick(banner) {
            window.location.href = banner.redirect_url
        }
    },
    data() {
        return {
            load: false
        }
    }
}
</script>

<style lang="less">
    .my-swipe {
        img {
            width: 100%;
        }
    }
    .img-loading {
        .van-swipe__track {
            height: 191px;
        }
        .van-image {
            width: 100%;
            height: 100%;
        }
    }
</style>
